/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo } from "react";
import { format as formatFns } from "date-fns";

import { Button } from "@/components/ui/button";
import { generateCells } from "./utils";
import { CalendarControls } from "@/components/calendar/Controls/CalendarControls";
import { WhiteBox } from "@/components/WhiteBox";
import { RemoveCrewMemberDocumentsPositionsDialog } from "@/components/dialogs/RemoveCrewMemberDocumentsPositionsDialog ";
import { EditCalendarDocumentsDialog } from "@/components/dialogs/EditCalendarDocumentsDialog";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { Loader, Download } from "lucide-react";
import { 
	type RotationDatesInfoInterface, 
	type CalendarPartInterface, 
	type RotationInterface,
	type CrewMemberInterface
} from "@/types/Calendar";
import { legend, prepareDate, getEmptyDaysCount, getEmptyFirstStartDate } from "@/utils/calendarUtils";
import { useGetCellColor } from "@/hooks/useGetCellColor";
import { useGetCrewMemberNotesRelatedWithCalendarQuery } from "@/app/api/slices/notesApiSlice";

import { type DayKindDialogHandler } from "@/components/calendar/DayKindDialog/types";

import { useDateFormatter } from "@/hooks/useDateFormatter";
import { AdaptModal } from "@/components/calendar/Modals/AdaptModal";
import { useCurrentVesselId } from "@/hooks/useCurrentVesselId";

type Props = { 
	rotation: undefined | null | string,
	pageHandler: (page: {dir: string, currentPage: number | undefined}) => void,
	page: undefined | number,
	maxPages: number,
	perPage: number,
	perPageHandler: (value: string) => void,
	isLoading: boolean,
	showDayKindModalHandler: DayKindDialogHandler,
	calendarDataGroup?: CrewMemberInterface[] | undefined,
	departmentId: number | string | undefined,
	positionId: number | string | undefined,
	contractId: number | string | undefined,
	rotationPeriodType: string | undefined,
};

const colorPallete = {
	header: "bg-slate-100",
	cell: "bg-slate-50"
};

export const Calendar = ({ rotationPeriodType, departmentId, positionId, contractId, calendarDataGroup, rotation, isLoading, pageHandler, page, maxPages, perPage, perPageHandler, showDayKindModalHandler }: Props) => {
	const vessel_id = useCurrentVesselId();
	const { format } = useDateFormatter();
	const [transformedData, setTransformedData] = useState<CalendarPartInterface[][] | []>([]);
	const [startDate, setStartDate] = useState<string | undefined>();
	const [endDate, setEndDate] = useState<string | undefined>();
	const [showChangeDayModal, setShowChangeDayModal] = useState<boolean>(false);
	const [hoveredCell, setHoveredCell] = useState<string | undefined>();
	const [showDocumentModal, setShowDocumentModal] = useState(false);
	const [document, setDocument] = useState<any>(null);
	const [userId, setUserId] = useState<string | undefined>();

	const { getClassFromType, isLoading: isLoadingCalendarColors } = useGetCellColor();

	useEffect(() => {
		setStartDate(undefined);
		setEndDate(undefined);
	}, [departmentId, positionId, contractId])

	const openDocumentModal = (data: any) => {
		if(!calendarDataGroup) return;
		const findDocBatchData = calendarDataGroup?.reduce((acc, crewData) => {
			if(!crewData.documents) return acc;
			const document = crewData.documents.find((doc: any) => doc.id === data);
			if(!document) return acc;
	
			acc = document;
			return acc;
		}, {})

		const findUserId = calendarDataGroup?.find((crewData) => crewData.documents?.find((doc: any) => doc.id === data));
		if(!findUserId) return;

		setUserId(String(findUserId.id));
		setDocument(findDocBatchData);
		setShowDocumentModal(true);
	}

	const onCloseDocumentModal = () => {
		setDocument(null);
		setShowDocumentModal(false);
	}

	const calculateHoveredCells = (startDate: string | undefined, hoveredCell: string | undefined) => {
		if(!startDate || !hoveredCell) return [];
		
		const startDateObj = new Date(startDate);
		const hoveredCellObj = new Date(hoveredCell);
		const diffTime = Math.abs(hoveredCellObj.getTime() - startDateObj.getTime());
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		const days = Array.from({ length: diffDays }, (_, i) => i + 1);
		const dates = days.map((day) => {
			const date = new Date(startDate);
			if( startDateObj > hoveredCellObj) {
				date.setDate(date.getDate() - day);
			} else {
				date.setDate(date.getDate() + day);
			}
			return formatFns(date, "y-MM-dd");
		});
		return dates;
	}
	
	const startDateHandler = (date: string) => {
		const isSame = startDate === date;
		setStartDate(isSame ? undefined : date);

		if(isSame) setEndDate(undefined);

		if(!isSame) {
			const formattedDate = formatFns(new Date(date), "y-MM-dd");
			const element = window.document.querySelector(`[data-cell-date="${formattedDate}"]`);
			if(element) {
				element.scrollIntoView({ 
					behavior: "smooth",
					block: 'center',
					inline: 'center'
				});
			}
		}
	}

	const endDateHandler = (date: string) => {
		setEndDate(prevDate => date === prevDate ? undefined : date);
		setShowChangeDayModal(true);
	}

	const showChangeDayModalHandler = () => {
		setShowChangeDayModal(false);
		setStartDate(undefined);
		setEndDate(undefined);
	}

	const rotationsInfo = calendarDataGroup?.reduce<{[key: number]: {onboard: RotationDatesInfoInterface, offboard: RotationDatesInfoInterface}}>((acc, rotation) => {
		rotation.rotations.forEach(({ id, onboard, offboard }: {id: number, onboard: RotationDatesInfoInterface, offboard: RotationDatesInfoInterface}) => {
			acc[id] = { onboard, offboard};
		});

		return acc;
	}, {});

	const crewData = calendarDataGroup?.map(({id, name}: {id: number, name: string}) => {
		return {
			id,
			name
		}
	});

	const rotationsDatesRanges = calendarDataGroup?.map((calendarData) => {
		const dateRanges = calendarData.rotations.map((rotation) => {
			const preparedDateRanges: {onboard?: {start_date: string, end_date: string}, offboard?: {start_date: string, end_date: string}} = {};

			if(rotation.onboard?.start_date && rotation.onboard?.end_date) {
				preparedDateRanges.onboard = {
					start_date: prepareDate(rotation.onboard.start_date, 'start_date'),
					end_date: prepareDate(rotation.onboard.end_date, 'end_date')
				}
			}
			if(rotation.offboard?.start_date && rotation.offboard?.end_date) {
				preparedDateRanges.offboard = {
					start_date: prepareDate(rotation.offboard.start_date, 'start_date'),
					end_date: prepareDate(rotation.offboard.end_date, 'end_date')
				}
			}
			return preparedDateRanges;
		});

		return {
			...calendarData,
			rotations: dateRanges
		}
	})

	const determineWhichDateIs = ({dateA, dateB, mode}: {dateA: string, dateB: string, mode: 'theNewest' | 'theOldest'}) => {
		const d_dateA = new Date(dateA);
		const d_dateB = new Date(dateB);

		if(mode === 'theNewest') {
			return d_dateA > d_dateB ? dateA : dateB;
		}
		return d_dateA > d_dateB ? dateB : dateA;
	};

	const datesRange = useMemo(() => {
		if(!rotationsDatesRanges) return null;

		// TODO: for each rotationsDatesRanges element go to .rotations first element and last element then save start_dates for onboard and end_dates for offboard
		const dates = rotationsDatesRanges.map((contract) => {
			const firstRotation = contract.rotations[0];
			const lastRotation = contract.rotations[contract.rotations.length - 1];

			if(firstRotation?.onboard && lastRotation?.offboard && firstRotation?.offboard && lastRotation?.onboard) {
				const firstDate = determineWhichDateIs({
					dateA: firstRotation.onboard.start_date, 
					dateB: firstRotation.offboard.start_date, 
					mode: 'theOldest'
				});

				const lastDate = determineWhichDateIs({
					dateA: lastRotation.onboard.end_date, 
					dateB: lastRotation.offboard.end_date, 
					mode: 'theNewest'
				});

				return [firstDate, lastDate];
			}
		});

		if(dates.length === 1) return dates[0];
		if(dates?.[0]?.[0] && dates?.[1]?.[0] && dates?.[1]?.[0] && dates?.[1]?.[1]) {
			const firstDate = determineWhichDateIs({
				dateA: dates[0][0], 
				dateB: dates[1][0], 
				mode: 'theOldest'
			});
	
			const lastDate = determineWhichDateIs({
				dateA: dates[0][1], 
				dateB: dates[1][1], 
				mode: 'theNewest'
			});
			return [firstDate, lastDate];
		}
		return null;

	}, [rotationsDatesRanges])

	const { data: notesData, isLoading: _isLoadingNotesData } = useGetCrewMemberNotesRelatedWithCalendarQuery({ 
		vesselId: vessel_id, usersIds: crewData?.map(user=>user.id), startDate: datesRange?.[0], endDate: datesRange?.[1]}, 
		{ skip: !vessel_id || !crewData || !datesRange?.[0] || !datesRange?.[1] }
	);

	// console.log("[PositionsCalendar] notesData: ", notesData);

	// const notesData = {
	// 	data: {
	// 		"notes": {
	// 			"8": { "note": "This is very long note. This is very long note. This is very long note. This is very long note. This is very long note. This is very long note. This is very long note. This is very long note. This is very long note. This is very long note." },
	// 			"9": { "note": "...dolor sit amet..." },
	// 			"10": { "note": "Test..." }
	// 		},
	// 		"dates": {
	// 			"2024-11-01": [8, 9],
	// 			"2024-11-12": [10]
	// 		}
	// 	}
	// }

	useEffect(() => {
		if(Array.isArray(calendarDataGroup) && calendarDataGroup.length) {
			const temp_calendarDataGroup = calendarDataGroup.map(({id, name, rotations}: CrewMemberInterface) => {
				const mergedRotations = rotations.reduce((acc: CalendarPartInterface[] | [], rotation: RotationInterface ) => {
					return [...acc, ...rotation.calendar.map((calendar) => ({...calendar, rotation_id: rotation.id, crew_member_id: id, crew_member_name: name}))];
				}, []);

				if(mergedRotations.length !== 0) {
					const firstDateInData = new Date(mergedRotations[0]?.start_date)?.getDay();
					const emptyDaysCount = getEmptyDaysCount(firstDateInData);
					const emptyFirstStartDate = getEmptyFirstStartDate(mergedRotations[0].start_date, emptyDaysCount);

					if(emptyDaysCount !== 0) {
						const firstRecordInArray = {
							type: "empty_day",
							start_date: format(emptyFirstStartDate),
							duration: emptyDaysCount
						}
						return [firstRecordInArray, ...mergedRotations]
					} else {
						return mergedRotations
					}
				} else {
					return [];
				}

			});

			setTransformedData(temp_calendarDataGroup);
		}
	}, [calendarDataGroup]);

	if(!isLoading && !calendarDataGroup) {
		<div className="flex grow items-center justify-center">
			<span>No data available</span>
		</div>
	}

	return (
		<WhiteBox className="relative flex flex-col min-h-96 grow pt-0">
			{!isLoading && contractId && (
				<CalendarControls 
					calendarDataGroup={calendarDataGroup}
					pageHandler={pageHandler}
					page={page}
					isLoading={isLoading}
					maxPages={maxPages}
					startDate={startDate}
					startDateHandler={startDateHandler}
				/>
			)}

			<div className="relative flex grow">
				{(isLoading || isLoadingCalendarColors) && (
					<div
						className="pt-4 absolute inset-0 z-10 flex h-full w-full items-center justify-center rounded-md"
						style={{ background: "rgba(255,255,255,0.6)", backdropFilter: "blur(2px)" }}
					>
						<Loader className="animate-spin duration-2000" color="#000" />
					</div>
				)}

				{!isLoading && !isLoadingCalendarColors && !contractId && (
					<div className="pt-4 flex grow items-center justify-center">
						<span className="text-sm">Select department, position and leave plan</span>
					</div>
				)}

				{!isLoading && !isLoadingCalendarColors && contractId && (
					<div className="overflow-x-auto pb-2 w-full">
						<div className="w-full relative p-1" style={{ minWidth: 500 }}>
							<AdaptModal 
							    show={showChangeDayModal}
								onHide={showChangeDayModalHandler}
								defaultStartDate={startDate}
								defaultEndDate={endDate}
								rotation={rotation ? rotation : undefined}
								positionId={positionId ? Number(positionId) : undefined}
								calendarDataGroup={calendarDataGroup}
							/>

							<Dialog open={showDocumentModal} onOpenChange={onCloseDocumentModal} defaultOpen={false}>
								<DialogContent className="sm:max-w-[500px]">
									<DialogHeader>
										<DialogTitle>Document</DialogTitle>
									</DialogHeader>

									<hr />

									{document && (
										<div className="space-y-2">
												<div className="flex items-center justify-start space-x-4 sm:justify-between">
													
													<div
														className="flex flex-col h-auto w-full flex-wrap "
													>
														<h4 className="text-left font-semibold sm:w-auto">
															Title: {document.title}
														</h4>

														<div className="text-sm flex flex-col sm:flex-nowrap sm:justify-center">
															<span className="hidden font-normal sm:inline-block">
																<span className="font-semibold">Created at:</span>{" "}
																{format(document.createdAt)}
															</span>
															<span className="font-normal">
																<span className="font-semibold">Updated at:</span>{" "}
																{format(document.updatedAt)}
															</span>
														</div>
													</div>
													
													<div className="flex items-center gap-1">
														<RemoveCrewMemberDocumentsPositionsDialog userId={userId} documentId={document.id} onSuccessCallback={onCloseDocumentModal}/>
														<EditCalendarDocumentsDialog userId={userId} document={document} onSuccessCallback={onCloseDocumentModal}/>
													</div>
												</div>

												{ document.end_date && (
													<div className="">
														<h3 className="font-semibold">Date Range:</h3>
														<div className="mt-1 rounded-sm bg-yellow-100 py-1 pl-2">
															<span>{format(document.start_date)} &ndash; {format(document.end_date || document.start_date)}</span>
														</div>
													</div>
												)}
												
												<div className="">
													<h3 className="font-semibold">Documents:</h3>
													<ul className="list-inside list-decimal">
														{document.files.map((file: {name: string, uploadedBy: string, url: string}) => {
															return (
																<li
																	key={file.name}
																	className="my-1 flex items-center justify-between rounded-sm bg-gray-100 pl-2"
																>
																	<div
																		className="truncate text-sm flex items-center"
																		title={`${file.name} &ndash; uploaded by ${file.uploadedBy}`}
																	>
																		<span className="truncate inline-block max:w-20 sm:max-w-40">{file.name}</span> &ndash; uploaded by {file.uploadedBy}
																	</div>
																	
																	<div>
																		<Button
																			variant="outline"
																			size="icon"
																			asChild
																			style={{ background: "#4CAF50", width: "28px", height: "28px" }}
																		>
																			<a className="flex items-center justify-center" href={file.url}>
																				<Download color="#fff" size={20} />
																			</a>
																		</Button>
																	</div>
																</li>
															);
														})}
													</ul>
												</div>
												<div className="">
													<h3 className="font-semibold">Description:</h3>
													<div className="mt-1 rounded-sm bg-yellow-100 py-1 pl-2">
														<span>{document.description}</span>
													</div>
												</div>
										</div>
									)}
								</DialogContent>
							</Dialog>

							{ (isLoading || isLoadingCalendarColors) && (
								<div className="z-10 absolute inset-0 flex h-full w-full items-center justify-center rounded-md" style={{background: "rgba(255,255,255,0.3)", backdropFilter: "blur(2px)"}}>
									<Loader className="animate-spin duration-2000" color="#000" />
								</div>
							)}

							<div className="flex flex-wrap">
								<div className="flex grow me-3 mb-3">
									{legend.map((item) => (
										<div className="flex items-center justify-content me-3" key={item.type}>
											<div className={`w-4 h-4 me-1 border rounded-md`} style={{background: getClassFromType(item.type)}}></div>
											<label className="text-xs text-nowrap ">{item.name}</label>
										</div>
									))}
									
								</div>
								<div className="mb-3">
									<div className="flex items-center">
										<label className="text-sm text-nowrap me-3">Rotations per page</label>
										<Select onValueChange={perPageHandler} value={String(perPage)}>
											<SelectTrigger>
												<SelectValue />
											</SelectTrigger>
											<SelectContent>
												<SelectGroup>
													{["2","3","5"].map((rotations_count) => (
														<SelectItem key={rotations_count} value={String(rotations_count)} defaultChecked={rotations_count === String(perPage)}>{rotations_count}</SelectItem>
													))}
												</SelectGroup>
											</SelectContent>
										</Select>
									</div>
								</div>
							</div>

							<div className="grid grid-cols-12 gap-1 md:gap-2 font-medium">
								<div className="py-2 rounded-md border border-gray-300 text-center col-span-3">
									<div className="flex flex-col">
										<div className="text-center text-xs">Employee</div>
										<div className="text-center text-xs">
											{ (rotationPeriodType === "months") && "Rotation month" }
											{ (rotationPeriodType === "weeks") && "Rotation week" }
											{ (rotationPeriodType === "leave_allowance") && "Week" }
										</div>
									</div>
								</div>

								<div className="py-2 rounded-md border border-gray-300 text-center col-span-2">
									<div className="flex flex-col">
										<div className="text-center text-xs">Month</div>
										<div className="text-center text-xs">Year</div>
									</div>
								</div>

								<div className={`${colorPallete.header} py-2 rounded-md border border-gray-300 text-center text-xs`}>Mon</div>
								<div className={`${colorPallete.header} py-2 rounded-md border border-gray-300 text-center text-xs`}>Tue</div>
								<div className={`${colorPallete.header} py-2 rounded-md border border-gray-300 text-center text-xs`}>Wed</div>
								<div className={`${colorPallete.header} py-2 rounded-md border border-gray-300 text-center text-xs`}>Thu</div>
								<div className={`${colorPallete.header} py-2 rounded-md border border-gray-300 text-center text-xs`}>Fri</div>
								<div className={`${colorPallete.header} py-2 rounded-md border border-gray-300 text-center text-xs`}>Sat</div>
								<div className={`${colorPallete.header} py-2 rounded-md border border-gray-300 text-center text-xs`}>Sun</div>
							</div>
							<div className="mt-2 grid grid-cols-12 gap-1 md:gap-2 ctrl__calendar">
								{
									generateCells({
										page,
										rotationsDatesRanges,
										hoveredCells: calculateHoveredCells(startDate, hoveredCell), 
										setHoveredCell, 
										changeDayStartDate: startDate, 
										endDate, 
										endDateHandler, 
										crewData, 
										data: transformedData, 
										showDayKindModalHandler, 
										rotation, 
										rotationsInfo,
										crewDocuments: calendarDataGroup,
										openDocumentModal,
										rotationPeriodType,
										cellColor: colorPallete.cell,
										notes: notesData?.data
									})
								}
							</div>
						</div>

					</div>
				)}
			</div>
		</WhiteBox>
	);
};
